body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f6f8f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Avenir-Medium";
  src: url("./fonts/Avenir-Medium/@font-face/2090551770be22b09600a40b0b4673b7.eot"); /* IE9*/
  src: url("./fonts/Avenir-Medium/@font-face/2090551770be22b09600a40b0b4673b7.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./fonts/Avenir-Medium/@font-face/2090551770be22b09600a40b0b4673b7.woff2")
      format("woff2"),
    /* chrome、firefox */
      url("./fonts/Avenir-Medium/@font-face/2090551770be22b09600a40b0b4673b7.woff")
      format("woff"),
    /* chrome、firefox */
      url("./fonts/Avenir-Medium/@font-face/2090551770be22b09600a40b0b4673b7.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("./fonts/Avenir-Medium/@font-face/2090551770be22b09600a40b0b4673b7.svg#Avenir-Medium")
      format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: "Avenir-Black";
  src: url("./fonts/Avenir-Black/@font-face/275de2221d9f0c4c9257d17f5a1e4006.eot"); /* IE9*/
  src: url("./fonts/Avenir-Black/@font-face/275de2221d9f0c4c9257d17f5a1e4006.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./fonts/Avenir-Black/@font-face/275de2221d9f0c4c9257d17f5a1e4006.woff2")
      format("woff2"),
    /* chrome、firefox */
      url("./fonts/Avenir-Black/@font-face/275de2221d9f0c4c9257d17f5a1e4006.woff")
      format("woff"),
    /* chrome、firefox */
      url("./fonts/Avenir-Black/@font-face/275de2221d9f0c4c9257d17f5a1e4006.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("./fonts/Avenir-Black/@font-face/275de2221d9f0c4c9257d17f5a1e4006.svg#Avenir Black")
      format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: "Avenir-Book";
  src: url("./fonts/Avenir-Book/@font-face/331ec49c0d78e469c42c1d814dd45838.eot"); /* IE9*/
  src: url("./fonts/Avenir-Book/@font-face/331ec49c0d78e469c42c1d814dd45838.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./fonts/Avenir-Book/@font-face/331ec49c0d78e469c42c1d814dd45838.woff2")
      format("woff2"),
    /* chrome、firefox */
      url("./fonts/Avenir-Book/@font-face/331ec49c0d78e469c42c1d814dd45838.woff")
      format("woff"),
    /* chrome、firefox */
      url("./fonts/Avenir-Book/@font-face/331ec49c0d78e469c42c1d814dd45838.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("./fonts/Avenir-Book/@font-face/331ec49c0d78e469c42c1d814dd45838.svg#Avenir Book")
      format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: "Avenir-Roman";
  src: url("./fonts/Avenir-Roman/@font-face/1a045963159927274c92b0444fb83c17.eot"); /* IE9*/
  src: url("./fonts/Avenir-Roman/@font-face/1a045963159927274c92b0444fb83c17.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./fonts/Avenir-Roman/@font-face/1a045963159927274c92b0444fb83c17.woff2")
      format("woff2"),
    /* chrome、firefox */
      url("./fonts/Avenir-Roman/@font-face/1a045963159927274c92b0444fb83c17.woff")
      format("woff"),
    /* chrome、firefox */
      url("./fonts/Avenir-Roman/@font-face/1a045963159927274c92b0444fb83c17.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("./fonts/Avenir-Roman/@font-face/1a045963159927274c92b0444fb83c17.svg#Avenir Roman")
      format("svg"); /* iOS 4.1- */
}
