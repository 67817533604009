.LinkButton {
  position: relative !important;
}

.LinkButton:hover {
  text-decoration: none !important;
}

.LinkButton::after {
  content: "" !important;
  height: 2px !important;
  width: 100%;
  position: absolute !important;
  bottom: -9px;
  background-color: transparent !important;
  transition: background-color 0.3s ease;
}

.LinkButton:hover.LinkButton::after {
  background-color: #004d7f !important;
  transition: background-color 0.3s ease;
}
