.table {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #c4c4c4;
  border: solid 1px #f6f8f9;
  background-color: #ffffff;
}

.headerCell {
  font-family: Avenir-Medium;
  font-size: 15px;
  font-weight: 500;
  color: #989898;
  text-transform: uppercase;
  padding: 15px 30px;
}

.rowHeader {
  background: white;
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
}

.rowBody {
  border-bottom: 1px solid #e5e5e5;
}

.bodyCell {
  padding: 18px 30px;
  font-family: Avenir-Medium;
  font-size: 15px;
  font-weight: 500;
  color: #1b1919;
}
